import { useCmsStory } from './useCmsStory'
import type { SbListingPage } from '~/storyblok/types/storyblok.gen'
import { CmsBaseFolder } from '~/constants/cms'

type Options = Partial<{
  options?: Parameters<typeof useRpc<'getCmsStory'>>[3]
  key: string
}>

export function useCmsListingContent({
  options,
  key = 'useCmsListingContent',
}: Options = {}) {
  const route = useRoute()

  const cms = useCmsStory<SbListingPage>({
    params: {
      baseFolder: CmsBaseFolder.PLP,
      slug: route.path,
      ignoreNotFound: true,
    },
    options,
    key: `${key}-${route.path}`,
  })

  const { data, error, execute: fetch, pending: fetching, status } = cms

  const content = computed(() => data.value?.content)
  const preListingContent = computed(
    () => content.value?.pre_listing_content || [],
  )
  const postListingContent = computed(
    () => content.value?.post_listing_content || [],
  )
  const teaserImage = computed(() => content.value?.teaser_image)
  const stages = computed(() => content.value?.stage)
  const disruptors = computed(() => content.value?.listing)

  return extendPromise(
    cms.then(() => ({})),
    {
      data,
      error,
      fetch,
      fetching,
      status,
      content,
      preListingContent,
      postListingContent,
      teaserImage,
      stages,
      disruptors,
    },
  )
}

export default useCmsListingContent
