<template>
  <div v-if="showHero" class="mx-auto max-w-7xl overflow-hidden">
    <KeepAlive v-if="stages">
      <div v-for="blok in stages" :key="blok._uid">
        <StoryblokComponent
          :blok="blok"
          class="mx-auto"
          :preload-media="true"
          critical
        />
      </div>
    </KeepAlive>
    <div v-else-if="teaserImage">
      <CmsImage :blok="teaserImage" is-teaser critical />
    </div>
    <div v-if="showFaceScan" class="mx-4 hidden sm:block xl:hidden">
      <FaceScan is-responsive />
    </div>
  </div>
  <PageContent>
    <KeepAlive v-if="preListingContent">
      <div v-for="blok in preListingContent" :key="blok._uid">
        <StoryblokComponent :blok="blok" />
      </div>
    </KeepAlive>
    <div
      id="header"
      class="flex flex-col justify-between sm:flex-row md:mb-8 md:items-center"
    >
      <div class="mr-4 inline-flex items-center">
        <Headline :loading="categoriesFetching" weight="normal" look="h1" inline
          >{{ headline || categoryHeadline }}
        </Headline>
        <span
          v-if="!productsFetching && unfilteredCount"
          class="-mb-1 ml-2 min-w-10 whitespace-nowrap text-sm md:text-xl"
        >
          ( {{ unfilteredCount }} )
        </span>
        <SkeletonLoader
          v-else
          type="custom"
          class="relative top-1 ml-2 inline-block h-6 w-12"
        />
      </div>
      <div v-if="showFaceScan" class="sm:hidden xl:flex">
        <FaceScan is-responsive />
      </div>
      <div
        class="mt-4 flex justify-between gap-4 text-sm sm:order-1 sm:mt-0 sm:items-start"
      >
        <section class="flex">
          <SkeletonLoader
            v-if="productStatus === 'idle' || productsFetching"
            type="headline"
            class="min-h-[42px] max-w-[107px]"
          />
          <SortingMenu
            v-else
            :selected="selectedSort"
            :values="sortingValues"
          />
        </section>
        <SkeletonLoader
          v-if="productStatus === 'idle' || productsFetching"
          type="custom"
          class="ml-0 min-h-[42px] w-1/2 min-w-[92px]"
        />
        <FimButton
          v-else
          is-small
          class="ml-0 sm:w-1/2"
          :type="appliedFiltersCount > 0 ? 'primary' : 'tertiary'"
          data-testid="list_button_filter"
          @click="toggleFilter"
        >
          <template #icon>
            <LazyIconFielmannFilter class="size-5" />
          </template>
          {{ $t('plp.filter') }}
          <template v-if="appliedFiltersCount > 0" #badge>
            <span
              class="ml-1 rounded-full bg-white px-2 py-0.5 text-sm text-black"
            >
              {{ appliedFiltersCount }}
            </span>
          </template>
        </FimButton>
      </div>
    </div>
    <div class="sm:flex">
      <div
        v-if="showSidebar"
        class="-ml-2 hidden w-1/5 min-w-[250px] pr-8 lg:inline-block"
      >
        <PLPSideNavigation
          :categories="currentCategories ? currentCategories.categories : []"
          :selected-category="selectedCategory"
          :fetching="categoriesFetching || !currentCategories"
        />
      </div>
      <div class="relative w-full">
        <ProductList
          :loading="productStatus === 'idle' || productsFetching"
          :refreshing="productsFetching"
          :products="products"
          :disruptors="disruptors"
          :product-count-loading="productCountData?.count ?? unfilteredCount"
          class="mt-8 grid w-auto grid-cols-12 gap-1"
          @click:product="trackProductClick"
          @intersect:row="trackViewListing"
        />
        <Pagination
          v-if="pagination"
          :current-page="pagination.page"
          :first-page="pagination.first"
          :last-page="pagination.last"
          :next-page="pagination.next"
          :previous-page="pagination.prev"
        />
        <KeepAlive v-if="postListingContent">
          <div v-for="blok in postListingContent" :key="blok._uid">
            <StoryblokComponent :blok="blok" />
          </div>
        </KeepAlive>
      </div>
      <LazyFilterSlideIn
        v-if="filters"
        :filters="filters"
        :refresh-product-count="refreshProductCount"
        :product-count-data="productCountData?.count ?? unfilteredCount"
        :product-count-fetching="productCountFetching"
        @update:applied-filters-count="appliedFiltersCount = $event"
      />
    </div>
  </PageContent>
</template>

<script setup lang="ts">
import type { Product } from '@scayle/storefront-nuxt'

type Props = {
  headline?: string
  showSidebar?: boolean
  showHero?: boolean
  categoryPath?: string
}

const props = withDefaults(defineProps<Props>(), {
  headline: undefined,
  showSidebar: true,
  showHero: true,
  categoryPath: undefined,
})

const route = useRoute()
const scope = effectScope()
const { configuredLocale, isMultilanguageShop, redirectToLocalePage } =
  useLocale()
const currentShop = useCurrentShop()
const { isInEditorMode } = useStoryblokHelpers()
const getRouteBaseName = useRouteBaseName()
onScopeDispose(() => scope.stop())

const { pageState, setPageState } = usePageState()
const { $featureToggle, $i18n } = useNuxtApp()
const { toggle: toggleFilter } = useSlideIn('FilterSlideIn')
const { ropoConfig } = $featureToggle
const { trackViewItemList, trackSelectItem } = await useTrackingEvents()

const {
  data: currentCategories,
  error: categoriesError,
  fetching: categoriesFetching,
} = await useCategories({
  params: { path: props.categoryPath ?? route.path },
  key: `${route.path}-category`,
  options: { immediate: true, server: true },
})

const selectedCategory = computed(() => currentCategories.value?.activeNode)

if (currentCategories?.value?.activeNode?.id && isNumericIdRoute(route.path)) {
  const fullSlug = currentCategories?.value?.activeNode?.path
  if (fullSlug && fullSlug !== route.params.slug) {
    const query = route.fullPath.split('?')[1]
    await redirectToLocalePage(fullSlug, query)
  }
}

const {
  stages,
  teaserImage,
  postListingContent,
  preListingContent,
  disruptors,
} = await useCmsListingContent()

const isRxPlp = computed(
  () =>
    selectedCategory.value?.id === 1 ||
    ropoConfig.value?.categoryPath === props.categoryPath,
)

let cheapestLensQualityIndex = 0
if (isRxPlp.value) {
  const { rxCheapestLensQualityIndexPrice } = useRxProducts()
  cheapestLensQualityIndex = rxCheapestLensQualityIndexPrice.value
}

const { selectedSort, sortingValues } = useProductListSort(selectedCategory)

const {
  products,
  productsFetching,
  pagination,
  filters,
  fetchProducts,
  refreshProductCount,
  productError,
  filterError,
  fetchParameters,
  isFiltered,
  unfilteredCount,
  productCountData,
  productCountFetching,
  productStatus,
} = await useProductList(
  selectedSort,
  pageState.value.plpType,
  props.categoryPath,
  cheapestLensQualityIndex,
)

const { categoryHeadline } = useFimCategories(
  currentCategories.value?.categories,
  selectedCategory,
)

onMounted(() => {
  fetchProducts(fetchParameters.value)
})

const plpCategory = computed(() => {
  if (products?.value?.[0]) {
    return getProductCategory(products.value[0])
  }
  return undefined
})

if (plpCategory.value) {
  setPageState('productCategory', plpCategory.value)
}

const isInternalCategoryPath = computed(
  () => route.path === `/${selectedCategory.value?.id}/`,
)

const robots = computed(() => {
  if (isInternalCategoryPath.value) {
    return 'noindex,nofollow'
  }

  if (isFiltered.value) {
    return 'noindex,follow'
  }
  return 'index,follow'
})

const metaTags = metaTagGenerator({
  description:
    (selectedCategory.value?.countryLevelCustomData
      ?.SEO_Description as string) ||
    $i18n.t('plp.seo_description', {
      categoryName: selectedCategory.value?.name.toLowerCase(),
    }),
  robots: robots.value,
  canonical: isFiltered.value
    ? undefined
    : getUrl(
        currentShop.value.domain,
        selectedCategory?.value?.path || route.fullPath || '',
      ),
})

useHead({
  title:
    selectedCategory.value?.countryLevelCustomData?.SEO_Headline ||
    selectedCategory.value?.name,
  ...metaTags,
})

defineOptions({ name: 'ProductListPage' })

const itemListName = computed(() =>
  [
    isBrandCategory(selectedCategory, currentCategories.value?.categories)
      ? BrandListingMetadataType
      : CategoryListingMetadataType,
    toValue(currentCategories)?.activeNode?.name,
  ]
    .filter(Boolean)
    .join(' | '),
)

const trackViewListing = ({
  items,
  row,
  itemsPerRow,
}: {
  row: number
  items: Product[]
  itemsPerRow: number
}) => {
  trackViewItemList(
    items,
    {
      id: toValue(selectedCategory)?.id,
      name: toValue(itemListName),
    },
    -1,
    row * itemsPerRow || 0,
  )
}

const trackProductClick = (product: Product) => {
  trackSelectItem(
    product,
    {
      categoryId: toValue(selectedCategory)?.id || '',
      categoryName: toValue(itemListName),
    },
    {
      id: toValue(selectedCategory)?.id,
      name: toValue(itemListName),
    },
  )
}

const error = computed(() => {
  return productError?.value || filterError?.value || categoriesError?.value
})

if (error.value) {
  throw error.value
}

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(
    configuredLocale,
    async (newLang) => {
      if (!isMultilanguageShop.value || isInEditorMode.value) {
        return
      }

      const trueRouteName = getRouteBaseName(route)
      if (
        currentShop.value.locale === newLang ||
        !selectedCategory.value ||
        trueRouteName !== 'category'
      ) {
        return
      }
      const path = `/${selectedCategory.value?.id}/`
      const query = route.fullPath.split('?')[1]

      await redirectToLocalePage(path, query)
    },
    { immediate: true },
  )

  watch(
    () => route.query,
    () => {
      fetchProducts(fetchParameters.value)
    },
  )

  watch(plpCategory, (newVal, oldVal) => {
    if (newVal !== oldVal) {
      setPageState('productCategory', newVal)
    }
  })
})

const appliedFiltersCount = ref(0)
const { faceWidth } = useFaceWidth()
const initialPlpType = ref(pageState.value.plpType)

const showFaceScan = computed(() => {
  if (initialPlpType.value === PAGE_TYPE_DEFAULT_PLP) return true
  return !!faceWidth.value && appliedFiltersCount.value > 0
})
</script>
